/* Modal.css */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 20px;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content input[type="file"] {
    border: none;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.submit-btn,
.close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn {
    background-color: #007bff;
    color: #fff;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.close-btn {
    background-color: #f44336;
    color: #fff;
}

.close-btn:hover {
    background-color: #d32f2f;
}
