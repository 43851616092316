.payment_details{
        margin-top: 30px;
}

.payment_details table {
    width: 95%;
    margin: 5px;
}

.payment_details th,
.payment_details td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}