.wallet-container{
text-align: center;
}

.profileheader {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    /* Position below the profile image */
    left: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}
.dropdown-content a {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: #333333;
    /* Text color */
    font-size: 14px;
    /* Font size */
    transition: all 0.3s ease;
    /* Transition effect */
}

.dropdown-content a:hover {
    background-color: #f9f9f9;
    /* Background color on hover */
    color: #000000;
    /* Text color on hover */
    font-size: 15px;
    /* Increase font size on hover */
}

.profileimg {
    cursor: pointer;
}

.dropdown-content{
    display: block;
}

/* .dropdown:hover .dropdown-content {
    display: block;
} */

.wallet-amount {
   font-weight: 500;
    cursor: default;
}