@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


* {
    margin: 0;
    padding: 0;

}

/* .sidebarbg {
    background-color: #E9E9E9;
} */

.kycshow {
    position: absolute;
    width: 100%;
    background-color: rgba(93, 89, 89, 0.456);
    height: 1000px;
    z-index: 30;
}

.kychidden {
    display: none;
}




.sidebarmain {
    display: flex;
    align-items: center;
    width: 301px;
    background-color: #FBFBFB;
    /* height: 100vh; */
    flex-direction: column;
}

.sidemainheader {
    display: flex;
    height: 104px;
    width: 100%;
    background-color: #FBFBFB;
    align-items: center;
}

.headermenuicon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.headermenuicon img {
    margin-left: 3px;
    width: 26px;
    height: 19px;
}

.headermenuicon h1 {
    text-align: center;
    color: #1D1B23;
    font-size: 28px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
}


.rightsideheader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

}

.profileheader {
    display: flex;
    gap: 15px;
}

.profileheader h1 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    width: 100%;
    text-align: end;

}

.profileheader span {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: 400;

}

.profileimg {
    width: 70px;
}



.dark_mode {
    background-color: rgb(14, 23, 51);
}

.light_mode {
    background-color: #F5F5F5;
}




.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
    margin-right: 50px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #000000;
}

input:focus+.slider {
    box-shadow: 0 0 1px #000000;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}




.notification {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
    border-radius: 2px;
}

.notificationimg {
    height: 31px;
    width: 31px;
}

.notification .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 2px 6px;
    border-radius: 50%;
    background: #FF7A00;
    color: white;
    text-align: center;
    font-size: 14px;
}



.line {
    height: 34px;
    width: 1px;
    background-color: #D3D6E4;
}


.darkmodetext {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    margin-right: 10px;
}

.mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.mainHeader2 {
    display: flex;
    margin-left: 34px;
    align-items: center;
}




.sideHeader {
    display: flex;
    align-items: center;
    height: 104px;
    width: 301px;
    justify-content: center;
    background-color: #FBFBFB
}

.sidebarlogo {
    display: flex;
    width: 206px;
    height: 48px;
    gap: 5.82px;
    align-items: center;
}

#sidebarlogoimg {
    width: 32px;
    height: 27px;
    margin-left: 8.98px;
}

.sidelogoName {
    font-family: "Manrope", sans-serif;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 800;
    color: #000000;
}

.divisioncomp {
    display: flex;
}

.ProfileCom {
    width: 100%;
    margin: 10px 10px;
}

.sidenav {
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    height: 336px;
    width: 301px;
}

/* .navitem {
    display: flex;
    align-items: center;
    list-style: none;
    width: 292px;
    height: 59px;
    gap: 20px;
    text-decoration: none;
} */

.navitem {
    display: flex;
    align-items: center;
    list-style: none;
    width: 292px;
    height: 59px;
    gap: 20px;
    text-decoration: none;

}

.navitemSelected {
    display: flex;
    align-items: center;
    list-style: none;
    width: 288px;
    height: 59px;
    gap: 20px;
    text-decoration: none;
    background-color: #E9E9E9;
    border-right: 4px solid black;
    border-inline-start-color: #000000;


}

.navitem:hover {
    width: 288px;
    background-color: #E9E9E9;
    border-right: 4px solid black;
    border-inline-start-color: #000000;
}


.navitem span {
    text-align: center;
    color: #717579;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.navitem img {
    width: 24px;
    height: 24px;
    color: #717579;
    margin-left: 50px;
}

.navitemSelected:hover {
    width: 288px;
    background-color: #E9E9E9;
    border-right: 4px solid black;
    border-inline-start-color: #000000;
}


.navitemSelected span {
    text-align: center;
    color: #717579;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.navitemSelected img {
    width: 24px;
    height: 24px;
    color: #717579;
    margin-left: 50px;
}

.bottom_nav {
    display: flex;
    flex-direction: column;
    height: 239px;
    width: 300px;
}

.bnavitem {
    display: flex;
    align-items: center;
    list-style: none;
    width: 300px;
    height: 59px;
    gap: 20px;
    text-decoration: none;
}

.bnavitem:hover {
    background-color: #E9E9E9;
}

.bnavitem span {
    text-align: center;
    color: #717579;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.bnavitem img {
    width: 24px;
    height: 24px;
    color: #717579;
    margin-left: 50px;
}

.categoryname {
    font-family: "Poppins", sans-serif;
    margin-left: 50px;
    text-align: center;
    color: #1C1C1C;
    font-size: 18px;
    font-weight: 500;
}

.sidefooter {
    width: 235px;
    height: 59px;
}

.sidefooter h1 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #1D1B23;
}

.sidefooter span {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #717579;
}


/* responsive */

@media only screen and (max-width:1300px) {

    .sidebarmain {
        width: 200px;

    }

    .sidemainheader {
        height: 104px;
    }

    .headermenuicon {
        gap: 20px;
    }


    .headermenuicon h1 {
        font-size: 18px;

    }

    .profileheader {
        gap: 10px;
    }


    .headermenuicon img {
        width: 22px;
        height: 18px;
    }

    .profileheader h1 {
        font-size: 13px;
    }

    .profileheader span {
        font-size: 10px;
    }


    .profileimg {
        width: 60px;
    }

    .switch {
        width: 36px;
        height: 18px;

    }



    .slider:before {


        height: 10px;
        width: 10px;
        bottom: 4px;

    }

    input:checked+.slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    .notificationimg {
        height: 25px;
        width: 25px;
    }

    .notification .badge {
        top: -5px;
        right: -3px;
        padding: 1px 3px;
        border-radius: 50%;
        background: #FF7A00;
        color: white;
        text-align: center;
        font-size: 10px;
    }


    .line {
        height: 28px;
        width: 1px;
    }

    .darkmodetext {

        font-size: 14px;

    }

    .sideHeader {
        height: 104px;
        width: 200px;

    }

    .sidebarlogo {
        display: flex;
        width: 150px;
        height: 48px;
        gap: 5.82px;
        align-items: center;
        /* background-color: royalblue; */
    }


    #sidebarlogoimg {
        width: 28px;
        height: 20px;
    }

    .sidelogoName {
        font-size: 14px;

    }

    .sidenav {
        height: 336px;
        width: 200px;
    }

    .navitem {
        width: 192px;

    }

    .navitemSelected {
        width: 188px;
    }

    .navitemSelected:hover {
        width: 188px;
    }

    .navitem:hover {
        width: 188px;
    }


    .navitemSelected span {
        font-size: 14px;
    }

    .navitem span {
        font-size: 14px;
    }

    .navitemSelected img {
        width: 18px;
        height: 18px;
        margin-left: 25px;
    }

    .navitem img {
        width: 18px;
        height: 18px;
        margin-left: 25px;
    }

    .bottom_nav {
        width: 199px;
    }

    .bnavitem {
        width: 199px;
        height: 59px;
        gap: 20px;
    }

    .bnavitem span {
        font-size: 14px;
    }


    .bnavitem img {
        width: 18px;
        height: 18px;
        margin-left: 25px;
    }

    .categoryname {
        margin-left: 25px;
        font-size: 15px;
    }

    .sidefooter {
        width: 135px;

    }

    .sidefooter h1 {

        font-size: 12px;

    }

    .sidefooter span {
        font-size: 10px;
        color: #717579;
    }



}






/* responsive 900 */

@media only screen and (max-width:900px) {

    .sidebarmain {
        width: 140px;

    }


    .sidemainheader {
        height: 80px;

    }

    .headermenuicon {
        gap: 10px;
    }


    .headermenuicon h1 {

        font-size: 12px;

    }

    .profileheader {
        gap: 10px;
    }


    .headermenuicon img {
        width: 18px;
        height: 14px;
    }

    .profileheader h1 {
        font-size: 10px;
    }

    .profileheader span {

        font-size: 10px;


    }


    .profileimg {
        width: 50px;
    }

    .switch {
        width: 26px;
        height: 14px;

    }



    .slider:before {


        height: 10px;
        width: 10px;
        top: 2px;
        bottom: 4px;

    }

    input:checked+.slider:before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
    }

    .notificationimg {
        height: 22px;
        width: 22px;
    }

    .notification .badge {
        top: -5px;
        right: -3px;
        padding: 1px 3px;
        border-radius: 50%;
        background: #FF7A00;
        color: white;
        text-align: center;
        font-size: 9px;
    }


    .line {
        height: 26px;
        width: 1px;
    }

    .darkmodetext {

        font-size: 10px;

    }

    .sideHeader {
        height: 80px;
        width: 160px;

    }

    .sidebarlogo {
        display: flex;
        width: 150px;
        height: 48px;
        gap: 5.82px;
        align-items: center;
        /* background-color: royalblue; */
    }


    #sidebarlogoimg {
        width: 22px;
        height: 20px;
    }

    .sidelogoName {
        font-size: 10px;

    }

    .sidenav {
        height: 336px;
        width: 140px;
    }

    .navitem {
        width: 132px;
        gap: 5px;

    }

    .navitemSelected {
        width: 132px;
        gap: 5px;

    }

    .navitemSelected:hover {
        width: 132px;
    }

    .navitem:hover {
        width: 132px;
    }

    .navitem span {
        font-size: 12px;
    }
    .navitemSelected span {
        font-size: 12px;
    }

    .navitemSelected img {
        width: 14px;
        height: 14px;
        margin-left: 10px;
    }
    .navitem img {
        width: 14px;
        height: 14px;
        margin-left: 5px;
        margin-left: 10px;

    }

    .bottom_nav {
        width: 139px;
    }

    .bnavitem {
        width: 139px;
        height: 59px;
        gap: 10px;
    }

    .bnavitem span {
        font-size: 12px;
    }


    .bnavitem img {
        width: 14px;
        height: 14px;
        margin-left: 10px;
    }

    .categoryname {
        margin-left: 5px;
        font-size: 13px;
    }

    .sidefooter {
        width: 135px;

    }

    .sidefooter h1 {

        font-size: 10px;

    }

    .sidefooter span {
        font-size: 8px;
        color: #717579;
    }



}