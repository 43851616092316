.container{
    flex: 1;
    flex-direction: column;
}
.header-form {
    padding: 20px;
    margin-bottom: 10px;
    /* flex: 1; */
}

.header-form .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* Align items at the ends */
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.form-item {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

/* .header-form form {
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.header-form h3 {

        font-weight: bold;
        margin-right: 10px;
        margin-left: 25px;
}

.header-form input[type='date'] {
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
}

.header-form button[type='submit'] {
    margin-left: 25px;
    padding: 5px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease,transform 0.3s ease;
    
}

.header-form button[type='submit']:hover {
    background-color: #007bff;
    transform: scale(1.05);
}

.wallet-recharge-btn {
    padding: 2px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    margin-top: 15px;
}

.wallet-recharge-btn:hover {
    background-color: #4db44d;
}

.transaction-history-btn {
    padding: 5px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.transaction-history-btn:hover {
    background-color: #b99125;
}

.full-width-line {
    width: 100%;
    border: none;
    height: 1px;
    background-color: #ccc;
    /* margin-bottom: 5px; */
}

.transaction_details table {
    width: 95%;
    margin: 5px;
    /* border-collapse: collapse; */
}

.transaction_details th,
.transaction_details td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

.empty_transaction{
margin-top: 35px;

}