 .popup-wrapper {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     /* Semi-transparent black */
     backdrop-filter: blur(5px);
     /* Blur effect */
     z-index: 1000;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .popup {
     background-color: #ffffff;
     padding: 20px;
     border: 1px solid #ccc;
     border-radius: 5px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
 }

  .popup button {
      margin: 10px;
      margin-top: 30px;
      padding: 5px 20px;
      cursor: pointer;
      border: none;
      border-radius: 3px;
  }

.popup button:hover {
    filter: brightness(1.1);
}

.popup button:hover:nth-of-type(1) {
    background-color: #7ab8fc;
}

.popup button:hover:nth-of-type(2) {
    background-color: #ff7a7a;
}